<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
          <!-- <el-form-item>
            <el-input v-model="search.kname" @change="Search" placeholder="姓名"></el-input>
          </el-form-item> -->
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addPower">新增管理员</el-button>
      </div>
    </div>

    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="角色">
        <template v-slot="scope">
          <span>{{ scope.row.role_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域">
        <template v-slot="scope">
          <span>{{ scope.row.region_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <el-button type="danger" size="small" @click="deletePower(scope.row.id)">删除</el-button>
          <!-- <router-link style="margin-left:10px" :to="'/admin/adminer/power/'+ scope.row.id">
            <el-button type="info" size="small">权限分配</el-button>
          </router-link> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination> -->

    <el-dialog title="新增权限分配" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" ref="form">
        <el-form-item label="角色" label-width="110px">
          <el-select v-model="form.role_id">
            <el-option v-for="item in rolelist" v-bind:key="item.id" v-bind:label="item.name" v-bind:value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域" label-width="110px">
          <el-select v-model="form.regin">
            <el-option v-for="item in regionlist" v-bind:key="item.code" v-bind:label="item.name" v-bind:value="item.code"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addconfirm">新增</el-button>
        </span>
      </template>
    </el-dialog>


  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {},
      adminer_id: '',
      list: [],
      dialogFormVisible: false,
      form: {
        role_id: '',
        regin: '',
      },
      rolelist: [],
      regionlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.GetRole()
    },
    GetRegion () {
      api.ReginList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.regionlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetRoleList () {
      this.api.RoleList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.rolelist = res.data.data.data
          this.GetRegion()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    // 已有区域角色
    GetRole () {
      this.api.AdminRoleList({
        adminer_id: this.adminer_id
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data
          // this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addPower () {
      this.dialogFormVisible = true
    },
    addconfirm () {
      this.api.AdminRoleUpdate({
        adminer_id: +this.adminer_id,
        role_id: this.form.role_id,
        region: this.form.regin
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          this.dialogFormVisible = false
          this.GetRole()
          return
        }
        this.$message.error('分配失败' + res.data.msg)
      })
    },
    deletePower (item) {
      this.$confirm('是否取消该条分配?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.AdminRoleDelete({
          id: item
        }).then(res => {
          if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.GetRole()
              return
            }
            this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback (){
      this.$router.push('/admin/adminer')
    }
  },
  filters: {},
  mounted () {
    console.log(this.$route.params.id,'参数')
    this.adminer_id = this.$route.params.id
    this.GetRole()
  },
  created () {
    this.GetRoleList()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
